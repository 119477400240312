<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div>1232323131313123</div>
      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>
      <!-- 弹窗 -->
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import zhcn from '@fullcalendar/core/locales/zh-cn'

export default {
  components: {
    FullCalendar,
  },
  data(){
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin], //视图插件
        initialView: "dayGridMonth",
        locale: zhcn, //中文
        headerToolbar: {},
        buttonText: {},
        slotLabelFormat: "HH:mm", //标签时间格式化
        eventTimeFormat: "HH:mm", //日程时间格式化
        allDaySlot: false, //不显示all-day（全天）
        firstDay: 0, //设置一周中显示的第一天是哪天，周日是o，周一是1，类推
        dayMaxEventRows: 3, //单元格最多显示日程数
        fixedWeekCount: false, //月视图显示周数,关闭显示正确周数
        // aspectRatio: 1.65, //日历单元格宽度与高度比，数值越大高度越小
        // displayEventTime: false, //是否显示日程时间，默认true
        // displayEventEnd: false, //是否显示日程结束时间，默认false
        // showNonCurrentDates: true, //在月视图中，是否应该呈现上个月或下个月的日期，默认true
        // weekends: true, //是否显示周末，默认true
        // hiddenDays: [], //隐藏某天 [2,4]:隐藏周二，周四
        events: [
          {
            id: 2,
            url: '',
            title: 'Meeting With Client',
            start: '2023-05-29',
            end: '2023-05-29',
            allDay: true,
            extendedProps: {
              calendar: 'Business',
            },
          },
        ], //日程
        eventDidMount: function (info) {
          if (info.event.extendedProps.status === "done") {
            info.el.style.color = "#BEBFC3";
            info.el.style.textDecoration = "line-through";
          }
        }, //事件挂载
        eventClick: this.handleDelete, //事件点击
        eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      //const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]
      const colorName = "primary"
      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
      },
    }
  }
  
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
